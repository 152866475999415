import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../../components/v2/Layout';
import { getUrlParams } from '../../../helpers/location';
import EmoriFormProfileAdmin from '../../../components/EmoriFormProfileAdmin';

const useStyles = makeStyles(theme => ({
  root: {},
}));

export default ({ location }) => {
  const classes = useStyles();
  const params = getUrlParams(location.search);
  const { corporate, room } = params;

  return (
    <Layout bg='bg4' title=''>
      <EmoriFormProfileAdmin corporate={corporate} roomId={room} />
    </Layout>
  );
};
